html {
  scroll-padding-top: 1.75rem;
}

.challengeTitle {
  font-size: 1.5rem;
  margin: 1.25rem 0 0.625rem 0;
}

.foobarTaskDescription {
  font-family: "Share Tech Mono", monospace;
  font-size: 1rem;
  background-color: #111111;
  color: #fff;
  padding: 1rem;
  margin: 1.25rem 0;
  overflow-x: auto;
}

.tableOfContents {
  position: sticky;
  background-color: rgb(24, 37, 54);
  border-bottom: solid 1px #444;
  list-style: none;
  padding: 0;
  margin: 0.5rem 0 0 0;
  display: flex;
  justify-content: center;
  overflow: hidden;
  display: flex;
}

.tocLayer1 {
  top: 0;
  height: 1.75rem;
  z-index: 5;
}

.tocLayer2 {
  top: 1.875rem;
  height: 1.875rem;
  z-index: 4;
}

.tableOfContents a {
  color: rgb(137, 156, 212);
  text-align: center;
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 0.1rem;
  width: 100%;
  max-width: 7.5rem;
}

.tableOfContents a:hover {
  background-color: #ddd;
  color: rgb(35, 39, 51);
}

.noBefore {
  overflow: auto;
  height: auto;
}

.noBefore::before {
  display: none;
}

p {
  overflow-x: hidden;
  overflow-y: hidden;
}

.numberedList {
  list-style: decimal;
  list-style-position: outside;
  padding-left: 1.25rem;
}

.tikzDiagram {
  display: flex;
  margin: auto;
  filter: invert();
  max-width: 25rem;
  min-width: 16.875rem;
  width: 100%;
}

.gridTable {
  margin: 0 auto;
}

.gridTable td {
  text-align: center;
  vertical-align: middle;
  width: 1.25rem;
  height: 1.25rem;
  aspect-ratio: 1;
}
.gridTable a {
  color: inherit;
}
.gridTable .s6 {
  border-bottom: 0.1rem solid white;
  background-color: #ff9900;
}
.gridTable .s12 {
  font-weight: bold;
}
.gridTable .s10 {
  border-right: 0.1rem solid white;
  background-color: #00ff00;
}

.gridTable .s14 {
  border-bottom: 0.1rem solid white;
  border-right: 0.1rem solid white;
  background-color: #ff9900;
}
.gridTable .s7 {
  background-color: #00ff00;
}
.gridTable .s1 {
  border-right: 0.1rem solid white;
}
.gridTable .s9 {
  background-color: #ff9900;
}
.gridTable .s8 {
  border-right: 0.1rem solid white;
  background-color: #ff0000;
}
.gridTable .s4 {
  background-color: #ff0000;
}
.gridTable .s3 {
  border-bottom: 0.1rem solid white;
  border-right: 0.1rem solid white;
}
.gridTable .s13 {
  border-bottom: 0.1rem solid white;
  background-color: #ff9900;
}
.gridTable .s2 {
  border-bottom: 0.1rem solid white;
}
.gridTable .s11 {
  background-color: #ff9900;
}
.gridTable .s5 {
  border-bottom: 0.1rem solid white;
  border-right: 0.1rem solid white;
  background-color: #ff9900;
}
.gridTable tr {
  height: 1.25rem;
}

figure {
  margin: 0;
  padding: 0;
}

figcaption {
  max-width: 31.25rem;
  margin: 0.3125rem auto;
  text-align: justify;
  font-style: italic;
  padding: 0;
}

@media only screen and (max-width: 600px) {
  .tableOfContents a {
    font-size: 0.625rem;
  }
}

@media only screen and (max-width: 380px) {
  .tableOfContents a {
    font-size: 0;
  }
  #aChallenge1:after {
    content: "1";
    font-size: 1rem;
  }
  #aChallenge2:after {
    content: "2";
    font-size: 1rem;
  }
  #aChallenge3:after {
    content: "3";
    font-size: 1rem;
  }
  #aChallenge4:after {
    content: "4";
    font-size: 1rem;
  }
  #aChallenge5:after {
    content: "5";
    font-size: 1rem;
  }
  #aBonus:after {
    content: "B";
    font-size: 1rem;
  }
}
