.conversationDiv {
  font-family: Söhne, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto,
    Ubuntu, Cantarell, Noto Sans, sans-serif, Helvetica Neue, Arial,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  background-color: rgb(52, 53, 65);
}

.conversationHeader {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.625rem;
}

.gptDiv,
.conversationHeader {
  background-color: rgb(68, 70, 84);
}

.chatgptLogoDiv {
  background-color: rgb(171, 104, 255);
  padding: 4px;
}

.chatUserDiv img {
  width: 100%;
  border-radius: 0.2rem;
  overflow: hidden;
}

.chatUserDiv {
  margin: 0 1.25rem 0 0;
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 0.125rem;
  flex-shrink: 0;
}

.userDiv,
.gptDiv {
  padding: 1.25rem 0.625rem 0.0625rem 0.625rem;
}

.messageBlockDiv {
  width: 100%;
  max-width: 37.5rem;
  margin: auto;
  display: flex;
}

.chatTextDiv p {
  margin: 0 0 1.25rem 0;
  text-align: start;
}

.chatTextDiv ol,
.chatTextDiv ul {
  display: flex;
  flex-direction: column;
  padding: 0 0 0 1rem;
  margin: 0 0 1.25rem 0;
}

.chatTextDiv ol {
  list-style: decimal;
}

.chatTextDiv ul {
  list-style: disc;
}

.chatTextDiv li {
  margin: 0;
  padding: 0 0 0.375rem 0.375rem;
}

.chatTextDiv li p {
  margin: 0;
}

#conversationContents {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: solid 1px #444;
  width: fit-content;
  padding: 0;
}

#conversationContents a {
  padding: 0.3125rem 0.625rem;
  text-align: center;
  width: 100%;
  border-top: solid 1px #444;
  color: rgb(137, 156, 212);
}

#conversationContents a:hover {
  background-color: #ddd;
  color: rgb(35, 39, 51);
}

.contentsDiv {
  display: flex;
  justify-content: center;
}

#conversationContents h3 {
  margin: 0.625rem;
  text-align: center;
}

@media only screen and (max-width: 450px) {
  .messageBlockDiv {
    display: block;
  }
  .chatUserDiv {
    float: left;
    margin: 0.3125rem 0.625rem 0 0;
  }
  .userDiv,
  .gptDiv {
    padding: 0.625rem 0.625rem 0.0625rem 0.625rem;
  }
}
