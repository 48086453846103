nav {
  font-size: 1rem;
  color: #e8eaed;
  max-width: 62.5rem;
  margin: auto;
  display: flex;
  height: 3.75rem;
  padding: 0 1rem;
  align-items: center;
  overflow: hidden;
}

nav div {
  margin-right: auto;
  height: 1rem;
  display: flex;
  align-items: center;
}

nav div img {
  height: 2rem;
  margin-right: 1rem;
}

nav a {
  color: white;
  margin: 0 0 0 1rem;
  text-decoration: none;
  word-wrap: normal;
}

nav a:hover {
  color: rgb(137, 156, 212);
  text-decoration: none;
}

.current-page {
  text-decoration: underline;
}

.current-page:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 400px) {
  #lachlan-nav-text {
    display: none;
  }
}
