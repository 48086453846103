.board {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 0.2rem;
  width: 90%;
  max-width: 500px;
  margin: 0 auto;
  background-color: #000000;
  padding: 0.2rem;
  align-items: center;
  justify-items: center;
  aspect-ratio: 1 / 1;
}

.cell {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4.5rem;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.cell:not(.winningCell) {
  transition: background-color 0.3s ease;
  background-color: #fff;
}

.cell.gameOver.cell:not(.winningCell) {
  color: rgba(0, 0, 0, 0.3);
  transition: color 0.3s ease;
}

.cell.x:not(.gameOver) {
  color: #df4444;
  transition: color 0.3s ease;
}

.winningCell.x {
  color: #df4444;
  transition: color 0.3s ease;
}

.cell.o:not(.gameOver) {
  color: #4444ce;
  transition: color 0.3s ease;
}

.winningCell.o {
  color: #4444ce;
  transition: color 0.3s ease;
}

.winner {
  font-size: 1.5rem;
  text-align: center;
}

.checkboxDiv {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  gap: 0.2rem;
  margin: 1rem;
}

.cell:hover::before {
  content: "";
  display: none;
}

.cell:not(.x, .o, .gameOver, .aiTurn):hover::before,
.cell.aiConsiderCell:not(.x, .o, .gameOver)::before {
  content: attr(data-hover);
  color: rgba(0, 0, 0, 0.2);
  font-size: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.winningCell.o {
  background-color: #9ddfc9;
  transition: background-color 0.3s ease;
}

.winningCell.x {
  background-color: #ece4b2;
  transition: background-color 0.3s ease;
}

@media only screen and (max-width: 410px) {
  .cell {
    font-size: 3rem;
  }
  .cell:not(.x, .o, .gameOver, .aiTurn):hover::before,
  .cell.aiConsiderCell:not(.x, .o, .gameOver)::before {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 300px) {
  .cell {
    font-size: 1.9rem;
  }
  .cell:not(.x, .o, .gameOver, .aiTurn):hover::before,
  .cell.aiConsiderCell:not(.x, .o, .gameOver)::before {
    font-size: 1.9rem;
  }
}
