.gameBoard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: min(100%, 500px);
  margin: 0 auto;
  background-color: #eff6fc;
  border-radius: 0.4rem;
}

.headerMessage {
  text-align: center;
  font-weight: bold;
  color: #324f75;
  font-size: 1.375rem;
  letter-spacing: 0.16rem;
  margin-top: 0.5rem;
}

.slots {
  position: relative;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 1fr;
  margin: 0.5rem min(1.5rem, 6%) 0 min(1.5rem, 6%);
}

.column {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 1fr);
  aspect-ratio: 1/6;
  cursor: pointer;
}

.slotConsider,
.humanTurn:hover,
.winningCell {
  background-color: rgba(216, 227, 234, 0.8);
  border-radius: 0.4rem;
}

.cell {
  padding: 14%;
  width: 100%;
}

.circle {
  width: 100%;
  height: 100%;
  background-color: none;
  border-radius: 50%;
  margin: 0;
  padding: 0;
  position: relative;
  border: min(0.2rem, 1vw) solid #324f75;
}

/* Define the keyframes for the flashing effect */
@keyframes flashEffectR {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
}

@keyframes flashEffectY {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
}

.lastCell.R::before {
  opacity: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  content: "";
  background-color: white;
  animation: flashEffectR 0.8s 2;
}

.lastCell.Y::before {
  opacity: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  content: "";
  background-color: white;
  animation: flashEffectY 0.8s 2;
}

.circle.R {
  background-color: #7fd7fd;
}

.circle.Y {
  background-color: #f8f778;
}

.aiDifficulty {
  display: flex;
  align-items: center;
  justify-content: center;
}

.settings {
  background-color: #21314a;
  border: 1px solid #324f75;
  border-radius: 0.4rem;
  width: 100%;
  margin-top: 0.4rem;
}

.resetButton {
  padding: 0.2rem;
}

.resetButton:hover {
  background-color: #324f75;
  color: #eff6fc;
}
